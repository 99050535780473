import * as React from 'react';
import { BooleanInput, Edit, SimpleForm, TextInput } from 'react-admin';

export const TestingComponentEdit = (props) => (
  <Edit title="Edit Testing Component" mutationMode="pessimistic" {...props}>
    <SimpleForm>
      <TextInput source="id" label="ID" disabled />
      <TextInput source="name" />
      <TextInput source="price" />
      <TextInput source="description" />
      <TextInput source="link" />
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
);
