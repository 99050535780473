import React from 'react';
import { Login } from 'react-admin';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';

const uiConfig = {
  signInFlow: 'redirect',
  signInSuccessUrl: '#/',
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  callbacks: {
    // signInSuccessWithAuthResult: (result) => {
    //   const credential = result.credential;
    //   // The signed-in user info.
    //   const user = result.user;
    //   // This gives you a Facebook Access Token. You can use it to access the Facebook API.
    //   const accessToken = credential.accessToken;
    //   console.log({ result, user, accessToken });
    // },
  },
};

const SignInScreen = () => (
  <StyledFirebaseAuth firebaseAuth={firebase.auth()} uiConfig={uiConfig} />
);

const CustomLoginForm = (props) => (
  <div>
    {/* <LoginForm {...props} /> */}
    <SignInScreen />
  </div>
);

const CustomLoginPage = (props) => (
  <Login {...props}>
    <CustomLoginForm {...props} />
  </Login>
);

export default CustomLoginPage;
