import * as React from 'react';
import { fetchUtils, Admin, Resource } from 'react-admin';
import crudProvider from 'ra-data-nestjsx-crud';
import { FirebaseAuthProvider } from 'react-admin-firebase';
import firebase from 'firebase/compat/app';
import firebaseConfig from './common/firebaseConfig';
import CustomLoginPage from './login/CustomLoginPage';
import i18nProvider from './common/i18nProvider';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import { BookingList } from './bookings/BookingList';
import { BookingEdit } from './bookings/BookingEdit';
import { BookingCreate } from './bookings/BookingCreate';
import { TimeSlotList } from './timeslots/TimeSlotList';
import { TimeSlotEdit } from './timeslots/TimeSlotEdit';
import { TimeSlotCreate } from './timeslots/TimeSlotCreate';
import { TestingComponentList } from './testingcomponents/TestingComponentList';
import { TestingComponentEdit } from './testingcomponents/TestingComponentEdit';
import { TestingComponentCreate } from './testingcomponents/TestingComponentCreate';

const backendApi = process.env.REACT_APP_BACKEND_API || 'http://localhost:4000';

const firebaseOptions = {
  logging: false,
  persistence: 'session',
};
firebase.initializeApp(firebaseConfig);
const authProvider = FirebaseAuthProvider(firebaseConfig, firebaseOptions);

const httpClient = (url, options = {}) => {
  return authProvider.getJWTToken().then(function (JWT) {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('Authorization', `Bearer ${JWT}`);
    return fetchUtils.fetchJson(url, options);
  });
};

const dataProvider = crudProvider(backendApi, httpClient);

const App = () => (
  <Admin
    authProvider={authProvider}
    loginPage={CustomLoginPage}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
  >
    <Resource
      name="bookings"
      list={BookingList}
      edit={BookingEdit}
      create={BookingCreate}
      icon={MonetizationOnIcon}
      options={{ label: 'Bookings' }}
    />
    <Resource
      name="events"
      list={TimeSlotList}
      edit={TimeSlotEdit}
      create={TimeSlotCreate}
      icon={DateRangeIcon}
      options={{ label: 'Time Slots' }}
    />
    <Resource
      name="testingcomponents"
      list={TestingComponentList}
      edit={TestingComponentEdit}
      create={TestingComponentCreate}
      icon={AppRegistrationIcon}
      options={{ label: 'Testing Components' }}
    />
  </Admin>
);

export default App;
