import * as React from 'react';
import {
  BooleanInput,
  DateTimeInput,
  NumberInput,
  Create,
  SimpleForm,
} from 'react-admin';

export const TimeSlotCreate = (props) => (
  <Create title="Create Time Slot" {...props}>
    <SimpleForm>
      <DateTimeInput source="start" label="Start Date & Time" />
      <DateTimeInput source="end" label="End Date & Time" />
      <NumberInput source="maxSeats" label="Max Seats" />
      <BooleanInput
        source="active"
        options={{
          checked: true,
        }}
      />
    </SimpleForm>
  </Create>
);
