import * as React from 'react';
import {
  List,
  Datagrid,
  EditButton,
  DateField,
  NumberField,
} from 'react-admin';

export const TimeSlotList = (props) => (
  <List
    title="Time Slots"
    perPage={25}
    sort={{ field: 'start', order: 'ASC' }}
    filter={{ archived: false }}
    {...props}
  >
    <Datagrid rowClick="toggleSelection">
      <DateField source="start" label="Start Date & Time" showTime />
      <DateField source="end" label="End Date & Time" showTime />
      <NumberField source="maxSeats" label="Max Seats" />
      <EditButton />
    </Datagrid>
  </List>
);
