import * as React from 'react';
import {
  BooleanInput,
  DateTimeInput,
  NumberInput,
  Edit,
  SimpleForm,
  TextInput,
} from 'react-admin';

export const TimeSlotEdit = (props) => (
  <Edit title="Edit Time Slot" mutationMode="pessimistic" {...props}>
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput source="id" label="ID" disabled />
      <DateTimeInput source="start" label="Start Date & Time" />
      <DateTimeInput source="end" label="End Date & Time" />
      <NumberInput source="maxSeats" label="Max Seats" />
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
);
