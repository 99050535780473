import * as React from 'react';
import {
  DateInput,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  SelectArrayInput,
} from 'react-admin';
import { parseISO, format } from 'date-fns';

const dateTimeRenderer = (choice) => {
  if (choice && choice.start) {
    return format(parseISO(choice.start), 'yyyy/MM/dd h:mmaaa');
  }
};

export const BookingCreate = (props) => (
  <Create {...props}>
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput source="email" label="Email" />
      <TextInput source="firstName" label="First Name" />
      <TextInput source="lastName" label="Last Name" />
      <TextInput source="phone" label="Phone" />
      <ReferenceInput
        label="Test Date & Time"
        source="event.id"
        reference="events"
        perPage={99999}
        sort={{ field: 'start', order: 'ASC' }}
      >
        <AutocompleteInput
          optionText={dateTimeRenderer}
          allowEmpty="true"
          resettable="true"
        />
      </ReferenceInput>
      <ReferenceArrayInput
        label="Purchased Testing Components"
        source="testingComponents"
        reference="testingcomponents"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectArrayInput optionText="name" allowEmpty />
      </ReferenceArrayInput>
      <TextInput source="paymentId" label="Payment Confirmation ID" />
      <TextInput source="confirmationId" label="Email Confirmation ID" />
      <DateInput source="dob" label="Date of Birth" />
      <TextInput source="address" />
      <TextInput source="city" />
      <TextInput source="region" />
      <TextInput source="postalCode" />
      <TextInput source="country" />
    </SimpleForm>
  </Create>
);
