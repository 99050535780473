import * as React from 'react';
import { BooleanInput, Create, SimpleForm, TextInput } from 'react-admin';

export const TestingComponentCreate = (props) => (
  <Create title="Create Testing Component" {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="price" />
      <TextInput source="description" />
      <TextInput source="link" />
      <BooleanInput
        source="active"
        options={{
          checked: true,
        }}
      />
    </SimpleForm>
  </Create>
);
