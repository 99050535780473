import * as React from 'react';
import {
  List,
  Datagrid,
  DateField,
  TextField,
  EmailField,
  EditButton,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';

export const BookingList = (props) => (
  <List
    title="Bookings"
    perPage={25}
    sort={{ field: 'id', order: 'ASC' }}
    filter={{ archived: false }}
    {...props}
  >
    <Datagrid rowClick="toggleSelection">
      <TextField source="id" label="ID" />
      <EmailField source="email" label="Email" />
      <TextField source="firstName" label="First Name" />
      <TextField source="lastName" label="Last Name" />
      <TextField source="phone" label="Phone" />
      <ReferenceField
        label="Test Date & Time"
        source="event.id"
        reference="events"
        sortBy="event.start"
      >
        <DateField source="start" showTime />
      </ReferenceField>
      <ReferenceArrayField
        label="Purchased Testing Components"
        source="testingComponents"
        reference="testingcomponents"
      >
        <SingleFieldList linkType={false}>
          <ChipField source="name" size="small" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="paymentId" label="Payment Confirmation ID" />
      <TextField source="confirmationId" label="Email Confirmation ID" />
      <DateField source="dob" label="Date of Birth" />
      <TextField source="address" />
      <TextField source="city" />
      <TextField source="region" label="Province/State" />
      <TextField source="postalCode" label="Postal/ZIP Code" />
      <TextField source="country" />
      <EditButton />
    </Datagrid>
  </List>
);
