import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
} from 'react-admin';

export const TestingComponentList = (props) => (
  <List
    title="Testing Components"
    perPage={25}
    sort={{ field: 'name', order: 'ASC' }}
    filter={{ archived: false }}
    {...props}
  >
    <Datagrid rowClick="toggleSelection">
      <TextField source="name" />
      <NumberField source="price" />
      <EditButton />
    </Datagrid>
  </List>
);
